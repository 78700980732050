import React from 'react';
import propTypes from "prop-types";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function CustomModal({ props }) {
    console.log(props)
    const {
        title,
        titleColor,
        description,
        handleClose,
        inputFields,
        open,
        btns,
        descriptionHTML 
    } = props


    return (
        <Dialog onClose={handleClose} open={open}>
            <Box p={3}>
                <Grid container p={0} spacing={0}>
                    <Grid item xs={12}>
                        <DialogTitle
                            textAlign="center"
                            color={titleColor}
                            padding={0}
                            marginBottom={2} >
                            {title}
                        </DialogTitle>
                    </Grid>
                </Grid>
                <DialogContent sx={{ padding: 0 }}>
                    {description ?
                        <Typography textAlign='center' variant="h6">{description}</Typography>
                        :
                        <>
                            {descriptionHTML}
                        </>
                    }
                    {inputFields?.map((field) => (
                        <Box key={field.key} marginTop={2}>
                            {field}
                        </Box>
                    ))}
                </DialogContent>
                <DialogActions>
                    {btns?.map((btn) => (
                        <Button
                            key={btn.key ?? 1}
                            disableRipple
                            variant={btn.variant}
                            color={btn.color}
                            fullWidth
                            style={{ marginTop: "1rem" }}
                            onClick={btn.onClick}
                            disabled={btn.disabled}
                        >
                            {btn.label}
                        </Button>
                    ))}

                </DialogActions>
            </Box>
        </Dialog >
    );
}

CustomModal.propTypes = {
    props: propTypes.any,
    title: propTypes.string,
    titleColor: propTypes.string,
    description: propTypes.string,
    handleClose: propTypes.func,
    inputFields: propTypes.array,
    open: propTypes.bool,
    btns: propTypes.array,
};
